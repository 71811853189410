@import '../../../assets/scss/variables.scss';

.BtnLabel {
    border-radius: 10px;
    word-wrap: none;
    font-size: 14px;
    line-height: 20px;
    color: white;
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        margin: 0;
    }
}

.BgDefault{ 
    background-color: $bright-blue;
}