@import "../../../assets/scss/variables.scss";

@keyframes progress-animation2 {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.height25 {
  height: calc(25% - 20px) !important;
}

.Box {
  position: relative;
  align-self: flex-start;
  height: 100%;
  //height: 375px;
  padding: 25px 30px;
  background-color: #ffffff;
  border-radius: 10px;
  // box-shadow: 1px 6px 15px 6px rgba(196, 200, 208, 0.4);
  overflow: hidden;

  display: flex;
  flex-direction: column;

  &::after {
    content: "";
    position: absolute;
    z-index: 10;
    bottom: 30px;
    left: 0;
    width: 100%;
    height: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background: linear-gradient(to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.85) 36%,
        rgba(255, 255, 255, 1) 100%);
    pointer-events: none;
    opacity: 0;
    transition: $transition;
    will-change: transform, opacity;
  }

  &SmallHeight {
    height: 135px;
  }

  &BigHeight {
    height: 445px;
  }

  &FullHeight {
    min-height: $full-height;
  }

  &Unavailable {
    opacity: 0.4;
    pointer-events: none;
  }

  &.Width1 {
    grid-column: span 1;
  }

  &.Wide {
    grid-column: span 3;
    width: 1180px;
  }

  &.Width11 {
    grid-column: span 1;
    width: 396px;
    height: 100%;
  }

  &.Battles {
    width: 580px;
    // grid-column: span 2;
    height: 550px;
  }

  &.Width2 {
    grid-column: span 2;
    width: 100%;
  }

  &.Width3 {
    grid-column: span 3;
    width: 1180px;
  }

  &.WithScroll {
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    scrollbar-width: none;
    scrollbar-color: transparent transparent;
  }
}

.Content {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  will-change: opacity;
  transition: opacity 0.2s;
  opacity: 1;
  animation: progress-animation2 0.3s ease-in-out 1;

  &.ContentHidden {
    // visibility: hidden;
    opacity: 0;
  }
}

.Header {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  // height: 75px;
  // margin-top: -25px;
  z-index: 100;
}

.HeaderNormal {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  height: 25px !important;
}

.zIndex {
  z-index: 499 !important;
}

.dnone {
  display: none !important;
}

.Toolbar {
  display: flex;
  align-items: center;
  z-index: 0;

  button,
  a {}

  &>* {
    margin-left: 12px;
  }
}

.Title {
  padding: 0;
  margin: 0;
  // margin-top: -6px;
  margin-bottom: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #040921;

  &.WithToolbar {
    flex-grow: 0;
  }
}

.FormatCount {
  margin-top: 3px;
  align-self: start;
  text-align: end;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(4, 9, 33, 0.32);
}

.Unavailable {
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
}

.shop {
  background: red;
}

.BoxScroll {
  &::after {
    opacity: 1;
  }

  .Arrow {
    opacity: 1;
  }
}

.BoxScrollPadding {
  padding-right: 20px;

  .Scroll,
  .ScrollTitle {
    padding-right: 10px;
  }
}

.Arrow {
  display: block;
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 34px;
  height: 34px;
  background-color: #ffffff;
  border: 2px solid #e3e9ef;
  border-radius: 50%;
  transform: translateX(-50%);
  z-index: 2000;
  transition: 0.2s ease-in;
  pointer-events: none;
  opacity: 0;
  will-change: transform, opacity;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    background-color: $light-grey;
    height: 3px;
    width: 13px;
  }

  &::before {
    left: 50%;
    transform: translate(-77%, -50%) rotate(45deg);
  }

  &::after {
    right: 50%;
    transform: translate(77%, -50%) rotate(-45deg);
  }
}

.Scroll {
  height: 100%;
}

.ScrollTitle {
  height: calc(100% - 48px);
}

.Popup {
  position: absolute;
  top: 62px;
  left: 0;
  width: 100%;
  height: 100%;
  // padding: 25px 0px 0px 30px;
  padding: 0 30px 0px;
  background-color: #ffffff;
  // z-index: 943000;
  z-index: 9;
  border-radius: 20px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.2s linear;
}

.PopupVisible {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.PopupTitle {
  margin-bottom: -25px;
}

.PopupButton {
  // position: absolute;
  // top: 29px;
  // right: 28px;
  margin-top: 2px;
  margin-left: 8px;
  float: right;
  width: 18px;
  height: 18px;
  border: none;
  padding: 0;
  // cursor: pointer;
  background-color: transparent;

  ////кнопка информации btn popup
  &:hover {
    svg path {
      fill: #52575d;
    }
  }
}

.ExecutionOfPlan {
  .PopupButtonOpen {
    left: 180px;
    top: 33px;
  }

  .PopupText {
    max-width: 80%;
  }
}

// .PersonalButtles {
//   .PopupButtonOpen {
//     left: 90px;
//   }
// }

.PopupScrollWrap {
  height: calc(100% - 48px);
}

.PopupText {
  margin: 0;
  padding-bottom: 20px;
  // padding-right: 30px;
  padding-right: 10px;
  font-size: 10px;
  line-height: 13px;
}

@media (max-width: $big-desktop) {
  .Box {
    width: 285px;
  }
}

@media (max-width: $desktop) {
  .Box {
    width: 270px;
    margin-bottom: 10px;

    &BigHeight {
      height: 300px;
    }
  }

  .ScrollTitle {
    height: calc(100% - 37px);
  }

  .PopupButton,
  .Popup {
    display: none;
  }
}

.BoxFullHeight {
  height: 100% !important;
}

.BoxFitHeight {
  height: fit-content !important;
}

.nonRelo {
  will-change: auto !important;
  position: static !important;
}

.ContentNormal {
  margin-top: 8px !important;
}