.dd__wrapper {
  width: 215px;
}


.dd__selectControl,
.dd__list {
  border-radius: 10px;
  border: none;
  max-height: unset;
  z-index: 2;
  scrollbar-width: thin;
}


.dd__selectControl {
  box-shadow: 0px 0px 0px 1px lightgrey;
}

.dd__list {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.13);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
    height: 300px;

  color: #53575D;
}

.dd__option {
  box-shadow: inset 0px 1px lightgrey;
  padding-left: 30px;
  div:first-child > & {
    box-shadow: none;
  }
}

.dd__selected {
  padding-left: 20px;
}

.dd__selectedItem {
  white-space: nowrap;
}

.dd__expandIcon {
  fill: #b3b8bd;
}
.Tittle {
  position: absolute;top: -20px;color: red; font-size: 30px;z-index: 2000;

}
