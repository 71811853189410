.Empty {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px!important;
  left: 308px;
  top: 2323px;

  background: #f0f3f6;
  border-radius: 7px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #B3B8BD;
}
