.Table {
  z-index: 0;
  width: 100%;
  border-collapse: collapse;
  th,
  td {
    padding: 12px 20px;
    height: 50px;
    text-align: start;
  }
  tbody tr:nth-child(even) td,
  thead th {
    background-color: #f1f4f7;
    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}
