.BoxNotification {
  padding:18px 21px 22px ;
  background: #FFFFFF;
  //box-shadow: 0 0 20px rgba(0, 0, 0, 0.13);
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-width: 393px;
  -webkit-box-shadow: 12px 8px 33px -5px rgba(34, 60, 80, 0.6);
  -moz-box-shadow: 12px 8px 33px -5px rgba(34, 60, 80, 0.6);
  box-shadow: 12px 8px 33px -5px rgba(34, 60, 80, 0.6);
}
.topBlock {
  display: flex;
  width: 100%;
}
.TextBlock {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */
  color: #53575D;
  margin-left: 11px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@keyframes progress-animation {
  from {
    width: 1%;
  }

  to {
    width: 100%;
  }
}
.btnClose {
  cursor: pointer;
  width: 87px;
  height: 29px;
  background: #0C5DFF;
  border-radius: 7px;
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {

  box-sizing: border-box;
  max-height: 100%;
  overflow-X: hidden;
  overflow-Y: auto;
  position: fixed;
  z-Index: 1000;
  box-shadow: 12px 8px 33px -5px rgba(34, 60, 80, 0.6);
}
.rightTop {
  top: 15%;
  right:calc(50% - 743px);
}
.downBlock {
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
  margin-left: 46px;
}

.topCenter {
  top: 50%;
  right: calc(50% - 305px);
}
.bottomCenter {
  bottom: 5%;
  right: calc(50% - 305px);
}
.ImgBlock {
  display: flex;
  align-items: center;
}
@media (max-width: 1650px) {
  .container {
    right: calc(50% - 614px);
  }
  .topCenter {
    top: 50%;
    right: calc(50% - 233px);
  }
  .bottomCenter {
    bottom: 5%;
    right:calc(50% - 233px);
  }
}
