@import "../../assets/scss/variables.scss";

.Wrapper {
  position: relative;
  z-index: 0;
  flex-grow: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  min-height: 100%;
  // background: url("../../assets/images/auth-bg.jpg") 50% no-repeat;
  background-size: cover;
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0c1750;
    opacity: 0.7;
  }
}

.Logo {
  display: block;
  margin-top: 41px;
  width: 75px;
  height: 36px;
}

.Form {
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  font-size: 12px;
  line-height: 19px;
  margin-top: 28px;
  color: $dark-grey;
}
.TittleAuth {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #53575D;
  margin-top: 33px;
}
.Input {
  width: 100%;
  height: 50px;
  padding: 0 24px;

  background-color: #fff;
  color: $dark-grey;
  transition: $transition;
  border: 1px solid #5F90ED;
  box-sizing: border-box;
  border-radius: 7px;
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
  &:last-of-type {
    margin-bottom: 20px;
  }
  &::placeholder {
    color: #b3b8bd;
  }
  &:focus::placeholder {
    color: #52575d;
  }
}

.FormError {
  .Input {
    border: 2px solid red;
    color: #ed4c4c;
  }
}

.ErrorMessage {
  color: $red;
  padding: 15px;
  margin-top: -20px;
}
.FormAuth {
  background: #FFFFFF;
  width: 368px;
  height: 400px;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Button {
  align-self: flex-end;
  margin-bottom: 74px;
  border: none;
  transition: $transition;
  cursor: pointer;
  width: 266px;
  height: 56px;
  left: 550px;
  top: 437px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  background: #5F90ED;
  box-shadow: 0px 4px 10px rgba(95, 144, 237, 0.15);
  border-radius: 7px;
  &:hover {
    background: #4B80E5;
  }
  &:active {
    background-color: #15a054;
  }
  &[disabled] {
    color: #000000;
    background-color: #eaf2ff;
    cursor: auto;
  }
}

.VideoBGContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
  opacity: 1;

  transition: opacity 0.5s;
  &.ToForeground {
    z-index: 1000;
  }
  &.Hidden {
    // display: none;
    opacity: 0;
  }
}

.VideoBG {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-aspect-ratio: "16/9") {
  .VideoBG {
    height: 300%;
    top: -100%;
  }
}
@media (max-aspect-ratio: "16/9") {
  .VideoBG {
    width: 100%;
    height: 100%;
    // width: 300%;
    // left: -100%;
  }
}
