@import '../../../assets/scss/variables.scss';

.Container {
  width: 100%;
  padding-left: $padding-desktop-big;
  padding-right: $padding-desktop-big;
}

@media (max-width: $big-desktop) {
  .Container {
    padding-left: $padding-desktop;
    padding-right: $padding-desktop;
  }
}

@media (max-width: $desktop) {
  .Container {
    padding-left: $padding-tablet;
    padding-right: $padding-tablet;
  }
}

@media (max-width: $tablet) {
  .Container {
    padding-left: 0px;
    padding-right: 0px;
  }
}
