.Price {
  font-size: 14px;
  font-weight: bold;
  // font-family: Rajdhani;
  background-color: #0C5DFF;
  padding: 5px 16px 5px;
  color: white;
  border-radius: 10px;
  text-align: center;
  min-width: 96px;
  height: 30px;
  white-space: nowrap;
}

.count {
  font-weight: bold;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
}
.text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
