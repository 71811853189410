@import "../../../assets/scss/variables.scss";

.Tabs {
  display: flex;
  align-items: center;
  //margin-bottom: 30px;
}

.Tab {
  text-align: center;
  margin-right: 20px;
  width: 140px;
  height: 30px;
  border-radius: 10px;
  border: 2px solid #0C5DFF;
;
  color: $bright-blue;
  font-size: 14px;
  // line-height: 25px;
  background: #ffffff;
  cursor: pointer;
  &:hover {
    // color: $dark-blue;
    // border-color: $dark-blue;
    opacity: 0.85;
  }
  &:active {
    opacity: 0.6;
  }
  &.Active {
    color: white;
    background-color: $bright-blue;
    cursor: auto;
    &:hover {
      opacity: 1;
    }
    &:active {
      opacity: 1;
    }
  }
}

.ToolbarTabs {
  margin: 0;
  margin-right: -20px;
}
