.MobileScreen {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: url("../../../assets/images/auth-bg.jpg") 50% no-repeat;
  background-size: cover;
  z-index: 93000000;

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0C1750;
    opacity: 0.95;
  }
}

.Logo {
  margin-bottom: 100px;
}

.ChangeWrap {
  display: flex;
  align-items: center;
}

.ChangeIcon {
  margin-right: 20px;
}

.ChangeText {
  margin: 0;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}
