$color: #d0dbe5;

.Loader {
  position: relative;
  width: 14px;
  height: 14px;

  border-radius: 14px;
  background-color: $color;
  transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  animation: Loader 1.15s ease-in-out infinite;
  -o-animation: Loader 1.15s ease-in-out infinite;
  -ms-animation: Loader 1.15s ease-in-out infinite;
  -webkit-animation: Loader 1.15s ease-in-out infinite;
  -moz-animation: Loader 1.15s ease-in-out infinite;
}

.Loader:before {
  content: "";
  position: absolute;
  background-color: $color;
  top: 0px;
  left: -28px;
  height: 14px;
  width: 14px;
  border-radius: 14px;
}

.Loader:after {
  content: "";
  position: absolute;
  background-color: $color;
  top: 0px;
  left: 28px;
  height: 14px;
  width: 14px;
  border-radius: 14px;
}

@keyframes Loader {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@-o-keyframes Loader {
  0% {
    -o-transform: rotate(0deg);
  }
  50% {
    -o-transform: rotate(180deg);
  }
  100% {
    -o-transform: rotate(180deg);
  }
}

@-ms-keyframes Loader {
  0% {
    -ms-transform: rotate(0deg);
  }
  50% {
    -ms-transform: rotate(180deg);
  }
  100% {
    -ms-transform: rotate(180deg);
  }
}

@-webkit-keyframes Loader {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
  }
}

@-moz-keyframes Loader {
  0% {
    -moz-transform: rotate(0deg);
  }
  50% {
    -moz-transform: rotate(180deg);
  }
  100% {
    -moz-transform: rotate(180deg);
  }
}
