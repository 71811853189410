@import "../../assets/scss/variables.scss";

html,
body {
  height: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  min-width: 320px;
  margin: 0;
  padding: 0;
  font-family: "Museo Sans", sans-serif;
  font-size: 16px;
  color: $dark-grey;
  overflow-y: scroll;
  background-color: #0c1750;
}
.ReactModal__Body--open {
  overflow: hidden!important;
  padding-right: 8px;

}
body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
body::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.705);
  // background: rgba(255, 255, 255, 0.308);
  border-radius: 5px;
}
body::-webkit-scrollbar {
  height: 5px;
  width: 8px;
  background-color: transparent;
}

body::-webkit-scrollbar-corner {
  display: none;
  width: 0;
  // background-color: #0c1750;//
  background-color: transparent;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

input:focus,
button:focus {
  outline: none;
}

.no-scroll {
  overflow: hidden;
  height: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.title {
  margin: 0 0 26px;
  font-size: 18px;
  line-height: 22px;
}

.os-scrollbar {
  z-index: 20 !important;
}

.number {
  font-family: "Museo Sans";
}

.recharts-tooltip-cursor {
  opacity: 0.5;
}

@media (max-width: $desktop) {
  body {
    font-size: 12px;
    line-height: 16px;
  }

  .no-scroll-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .title {
    margin: 0 0 15px;
    font-size: 16px;
  }
}
