.Wrap {
  display: flex;
  flex-direction: column;
  width: 380px
}

.List {
  flex: 1;
  list-style: none;
  padding: 0;
  margin: 0;
}

.HistoryItem {
  display: flex;
  align-items: center;
  height: 34px;
  font-size: 0.7em;
  letter-spacing: -0.3px;
  margin-bottom: 9px;
}

.HistoryItemAmount {
  margin-right: 21px;

  .HistoryItem & {
    background-color: #d0dbe5;
    color: black;
    flex-shrink: 0;
  }
}

.SmallTitle {
  line-height: 12px;
}

.HistoryItemImg {
  width: 30px;
  margin-right: 5px;
  border-radius: 10px;
}

.HistoryItemDate {
  margin-left: auto;
}

.ml2 {
  margin-left: 20px;
  height: 100%;
}

.OperationOffice {
  width: 100%;
  margin-bottom: 17px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 33px;
  padding-top: 4px;
}

.ItemTittle {
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */
  color: #53575D;
}

.ItemAmount {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  color: #898D92;
}

.ItemData {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #898D92;
  margin-top: 4px;
}

.TittleOperation {
  margin-left: 13px;
  display: flex;
  margin-top: -1px;
  flex-direction: column;
  justify-content: flex-start;
}

.LeftTittleBlock {
  display: flex;
  align-items: center;
}

.FullBlock {
  background: #f0f3f6;
  border-radius: 7px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    color: #B3B8BD;
  }
}

.h100 {
  height: 100% !important;
}