@import '../../../assets/scss/variables.scss';

.Badge {
  width: 100%;
  background-color: #F1F4F7;
  border-radius: 10px;
  color: black;
}

.BadgeContent {
  width: 100%;
  padding: 20px 25px;
}