$duration: 1.5s;

@keyframes animate-circle {
  from {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

.Preloader {
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0c1750;
}

.Circle {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 150px;
  width: 150px;
  background: #ffffff;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: animate-circle $duration cubic-bezier(0.9, 0.24, 0.62, 0.79) infinite;

  &:nth-of-type(1) {
    animation-delay: 0;
  }
  &:nth-of-type(2) {
    animation-delay: calc(#{$duration} / -3);
  }
  &:nth-of-type(3) {
    animation-delay: calc(#{$duration} / -6);
  }
}
