@import "../../../assets/scss/variables.scss";

.Wrap {
  // width: 500px;
  // height: 500px;
  // margin: 200px auto;
  height: 100%;

  // > button {
  //   padding: 15px;
  // }
  position: relative;
}

.Scroll {
  overflow-y: scroll;
  height: 100%;

  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  &::-webkit-scrollbar {
    width: 1px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  // div {
  //   width: 100%;
  //   height: 100px;
  // }
}

$show-transition: opacity 0.2s;
.ArrowBottom,
.ArrowTop {
  opacity: 0;
  pointer-events: none;
  bottom: 0px;
  display: block;
  position: absolute;
  left: 50%;
  width: 34px;
  height: 34px;
  background-color: #ffffff;
  border: 2px solid #e3e9ef;
  border-radius: 50%;
  transform: translateX(-50%);
  z-index: 2000;
  transition: $show-transition;
  // pointer-events: none;
  will-change: transform, opacity;
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    background-color: $light-grey;
    height: 3px;
    width: 13px;
  }

  &::before {
    left: 50%;
    transform: translate(-77%, -50%) rotate(45deg);
  }

  &::after {
    right: 50%;
    transform: translate(77%, -50%) rotate(-45deg);
  }
}

.ArrowTop {
  bottom: unset;
  top: 0px;
  &::before {
    left: 50%;
    transform: translate(-77%, -50%) rotate(-45deg);
  }

  &::after {
    right: 50%;
    transform: translate(77%, -50%) rotate(45deg);
  }
}

.ScrollGradient {
  position: absolute;
  z-index: 2;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 60px;

  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.85) 56%,
    rgba(255, 255, 255, 1) 100%
  );
  pointer-events: none;
  opacity: 0;
  transition: $show-transition;
  will-change: transform, opacity;
  &.TopScroll {
    transform: rotate(180deg);
    top: -2px;
    bottom: unset;
  }
}

.Show {
  opacity: 1;
  pointer-events: initial;
}
