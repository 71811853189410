.Wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 30px;
  max-width: 400px;
  :global(.dd__wrapper),
  input {
    flex-shrink: 0;
    width: 100%;
    // margin-left: 45px;
  }
}
.wrapPurchase {
  width: 326px;
  position: relative;
}
.Label {
  display: block;

  > div:first-child {
    margin-bottom: 8px;
  }

  :global(.dd__selectControl) {
    height: 38px;
  }
}

.LabelInline {
  display: flex;
  align-items: center;
}

.Buttons {
  margin: 25px auto 0;
  width: 100%;
  display: flex;

  > button {
    flex: 1 0;
    &:nth-child(2) {
      margin-left: 30px;
    }
  }
}

.Title {
  margin-bottom: 20px;
  text-align: center;
}
.buttonAdd {
  width: 32px;
  height: 32px;
  background: #F0F3F6;
  border-radius: 10px;
}
.dflexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rightContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;

}
.leftContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 50%;

}
.countTittle {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #5F90ED;
  text-align: left;
  width: 100%;
}
.count {
  width: 100%;

  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #53575D;
}
.inputCount {
  width: 266px;
  height: 48px;
  display: flex;
  border: 1px solid #5F90ED;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 8px 16px;
  margin-top: 20px;
  display: none;
}
.itemText {
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #52575D;
}
.itemDiscrtiption {
  width: 100%;
  margin-top: 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  color: #898D92;
}
.btnBuy {
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  color: white;
  background: #0C5DFF;
  cursor: pointer;
}
.btnBl {
  width: 100%;
  margin-top: 16px;
  border-top: 1px solid #D2DBE4;
  padding-top: 16px;
}
.exit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
.tittle {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
